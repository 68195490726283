import { SessionProvider } from "next-auth/react";
import "../styles/globals.scss";
import Head from "next/head";
import { ErrorBoundary } from "@sentry/nextjs";
import Auth from "../components/Auth";
import { AppProps } from "next/app";

export default function App({ Component, pageProps }: AppProps) {
  return (
    <SessionProvider session={pageProps.session}>
      <Head>
        <title>PII Masking</title>
        <link rel="icon" href="/favicon.png" />
        <meta
          name="description"
          content="A web-based tool to hash PII columns of CSV files"
        />
      </Head>

      <ErrorBoundary
        fallback={
          <div className="d-flex align-items-center justify-content-center flex-column flex-grow-1">
            <h2>A fatal error occurred</h2>
            <p className="lead">Try refreshing the page.</p>
          </div>
        }
      >
        <Auth>
          <Component {...pageProps} />
        </Auth>
      </ErrorBoundary>
    </SessionProvider>
  );
}
