import { signIn, useSession } from "next-auth/react";
import { ReactNode, useEffect } from "react";
import { setUser } from "@sentry/nextjs";
import { Spinner } from "react-bootstrap";

export interface AuthProps {
  children: ReactNode;
}

export default function Auth({ children }: AuthProps) {
  const { data: session, status } = useSession({
    required: true,
    onUnauthenticated() {
      signIn("azure-ad");
    },
  });

  useEffect(() => {
    if (session?.user) {
      setUser({
        email: session.user.email ?? void 0,
        username: session.user.name ?? void 0,
      });
    }
  }, [session]);

  if (status === "loading")
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );

  return <>{children}</>;
}
