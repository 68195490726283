import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://16927e198f0640ca904363da963f131f@o947277.ingest.sentry.io/6255111",
  tracesSampler: (samplingContext) => {
    if (samplingContext.transactionContext.name === "/404") {
      return 0.01;
    } else {
      return 0.1;
    }
  },
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
});
